import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/text-input/TextInput.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/text-input" storybook="https://workday.github.io/canvas-kit/?path=/story/components-inputs-text-input--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=3984-206&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2a846e64bb7b9257faf3945ef916b44f/536c7/component-anatomy-text-input.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "20%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Text Input in its default state with top label.",
              "title": "Image of a Text Input in its default state with top label.",
              "src": "/static/2a846e64bb7b9257faf3945ef916b44f/50383/component-anatomy-text-input.png",
              "srcSet": ["/static/2a846e64bb7b9257faf3945ef916b44f/1efb2/component-anatomy-text-input.png 370w", "/static/2a846e64bb7b9257faf3945ef916b44f/50383/component-anatomy-text-input.png 740w", "/static/2a846e64bb7b9257faf3945ef916b44f/536c7/component-anatomy-text-input.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Title of the Text Input.`}</li>
        <li parentName="ol"><strong parentName="li">{`Input Container:`}</strong>{` Rectangular container that houses the placeholder and input text.`}</li>
        <li parentName="ol"><strong parentName="li">{`Placeholder/Input Text:`}</strong>{` Placeholder text is optional and shows an example of how to format
the text for what the input is used for.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Text Inputs can only support words, numbers or characters.`}</li>
        <li parentName="ul">{`Standard and Wide Text Inputs does not support images or any text styling.`}</li>
        <li parentName="ul">{`To ensure we don’t overwhelm users, there shouldn’t be more than two Wide Text Inputs on a page.`}</li>
        <li parentName="ul">{`For all Text Inputs on Web, a user clicking into an input or label that is not disabled will
trigger the text cursor to appear, allowing users the ability to type. As the user types in the
Text Input, the placeholder text is replaced with the user’s input.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Text Input is typically a form element used to collect user data that includes words, numbers or
characters.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`If styling is needed, such as for configuring email messages, you can use a Rich Text Editor
instead.`}</li>
        <li parentName="ul">{`Use a `}<a parentName="li" {...{
            "href": "/components/inputs/text-area"
          }}>{`Text Area`}</a>{` when you need to let users enter an amount of text
that’s longer than a single line.`}</li>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/inputs/select"
          }}>{`Select`}</a>{`, `}<a parentName="li" {...{
            "href": "/components/inputs/radio"
          }}>{`Radio`}</a>{` or
`}<a parentName="li" {...{
            "href": "/components/inputs/checkbox"
          }}>{`Checkboxes`}</a>{` if there are predetermined data that a user should not
input themselves.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-text-inputs-impact-the-accessible-experience"
      }}>{`How Text Inputs Impact the Accessible Experience`}</h3>
      <p>{`Programmatically associating labels and instructions to form inputs is one of the most important
foundational practices for building accessible forms.`}</p>
      <p>{`For Text Input, placeholder values are best used for hints or examples of valid input. Designers
should avoid relying on placeholder text for explaining the purpose of the input, as placeholders
often don’t provide enough contrast against the text input background. In addition to lower
contrast, placeholders disappear once users enter information, impacting users with memory loss,
cognitive disabilities, or who are simply distracted.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Text Input must have a focus indicator that is highly visible against the background and
against the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for
more information.`}</p>
      <p>{`Text Input must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus into and out of each text area`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Text Input must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`This component is a text field`}</li>
        <li parentName="ul">{`The accessible name (label) describing the purpose of the field`}</li>
        <li parentName="ul">{`Text or placeholder values inside the field`}</li>
        <li parentName="ul">{`If applicable, whether the field is required, disabled, or invalid`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`No design annotations needed`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Text Input must have an `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` attribute referencing the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the
error text when an inline alert or error is shown.`}</li>
        <li parentName="ul">{`Text Input must have a `}<inlineCode parentName="li">{`required`}</inlineCode>{` attribute when the field is required for form submission.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`<label>`}</inlineCode>{` element must be added with a `}<inlineCode parentName="li">{`for`}</inlineCode>{` attribute referencing the
unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the associated text area.`}</li>
        <li parentName="ul">{`[Included in component]`}{` An `}<inlineCode parentName="li">{`aria-invalid=”true”`}</inlineCode>{` attribute must be set on the Text Input when the
field has an error condition.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` attribute must be set on the Text Input when the field is
disabled.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Labels for Text Inputs are written in title case.`}</li>
        <li parentName="ul">{`Refer to the guidelines on `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/placeholder-text"
          }}>{`Placeholder Text`}</a>{` in the Content
Style Guide for more tips on how to write placeholder text.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/91b42122e2e91fc2f633a3040e16039f/536c7/text-input-mobile-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.135135135135133%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input with annotation markers describing the anatomy.",
                "title": "Image of a Text Input with annotation markers describing the anatomy.",
                "src": "/static/91b42122e2e91fc2f633a3040e16039f/50383/text-input-mobile-anatomy.png",
                "srcSet": ["/static/91b42122e2e91fc2f633a3040e16039f/1efb2/text-input-mobile-anatomy.png 370w", "/static/91b42122e2e91fc2f633a3040e16039f/50383/text-input-mobile-anatomy.png 740w", "/static/91b42122e2e91fc2f633a3040e16039f/536c7/text-input-mobile-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Text used as a title or brief description of the input.`}</li>
          <li parentName="ol"><strong parentName="li">{`Placeholder/Input Text:`}</strong>{` Placeholder text is optional and shows an example of the intended
input.`}</li>
          <li parentName="ol"><strong parentName="li">{`Helper Text (Optional):`}</strong>{` Message to aid in users understanding of acceptable input text.`}</li>
          <li parentName="ol"><strong parentName="li">{`Input Container:`}</strong>{` Rectangular container that houses the placeholder and input text.`}</li>
          <li parentName="ol"><strong parentName="li">{`Required Asterisk (Optional):`}</strong>{` Appears only when the input is required.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states"
        }}>{`Interaction States`}</h2>
        <p>{`Mobile inputs include several interactive states; inactive, active, disabled and pressed states.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/536c7/text-input-mobile-inactive.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its inactive state.",
                "title": "Image of a Text Input in its inactive state.",
                "src": "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/50383/text-input-mobile-inactive.png",
                "srcSet": ["/static/8aef08d098c8ef4e53cf5e6a5bb40b08/1efb2/text-input-mobile-inactive.png 370w", "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/50383/text-input-mobile-inactive.png 740w", "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/536c7/text-input-mobile-inactive.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Inactive states are the default state if the input is not active, disabled or displaying an alert.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dabf9ad1730051eb432c41e643edfb4e/536c7/text-input-mobile-active.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its active state.",
                "title": "Image of a Text Input in its active state.",
                "src": "/static/dabf9ad1730051eb432c41e643edfb4e/50383/text-input-mobile-active.png",
                "srcSet": ["/static/dabf9ad1730051eb432c41e643edfb4e/1efb2/text-input-mobile-active.png 370w", "/static/dabf9ad1730051eb432c41e643edfb4e/50383/text-input-mobile-active.png 740w", "/static/dabf9ad1730051eb432c41e643edfb4e/536c7/text-input-mobile-active.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Active states are displayed with a blue border, a cursor, and a keyboard.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f4715750c73552fa995b4213c428c497/536c7/text-input-mobile-disabled.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its disabled state.",
                "title": "Image of a Text Input in its disabled state.",
                "src": "/static/f4715750c73552fa995b4213c428c497/50383/text-input-mobile-disabled.png",
                "srcSet": ["/static/f4715750c73552fa995b4213c428c497/1efb2/text-input-mobile-disabled.png 370w", "/static/f4715750c73552fa995b4213c428c497/50383/text-input-mobile-disabled.png 740w", "/static/f4715750c73552fa995b4213c428c497/536c7/text-input-mobile-disabled.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Disabled states are grayed out to prevent users from interacting with the input.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/87723c0344932fed38cced149c39887a/536c7/text-input-mobile-pressed.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its pressed state.",
                "title": "Image of a Text Input in its pressed state.",
                "src": "/static/87723c0344932fed38cced149c39887a/50383/text-input-mobile-pressed.png",
                "srcSet": ["/static/87723c0344932fed38cced149c39887a/1efb2/text-input-mobile-pressed.png 370w", "/static/87723c0344932fed38cced149c39887a/50383/text-input-mobile-pressed.png 740w", "/static/87723c0344932fed38cced149c39887a/536c7/text-input-mobile-pressed.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`While actively pressing on an input, the container fill is displayed in gray to provide feedback to
the user of their touch.`}</p>
        <h2 {...{
          "id": "notification-states"
        }}>{`Notification States`}</h2>
        <p>{`Notification states on mobile include an additional visual affordance compared to web notifications.
They include an icon and a notification label.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/85bd863f552721e27efbf02f4218aceb/536c7/text-input-mobile-notification.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "20.54054054054054%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of two Text Input showing the notification states.",
                "title": "Image of two Text Input showing the notification states.",
                "src": "/static/85bd863f552721e27efbf02f4218aceb/50383/text-input-mobile-notification.png",
                "srcSet": ["/static/85bd863f552721e27efbf02f4218aceb/1efb2/text-input-mobile-notification.png 370w", "/static/85bd863f552721e27efbf02f4218aceb/50383/text-input-mobile-notification.png 740w", "/static/85bd863f552721e27efbf02f4218aceb/536c7/text-input-mobile-notification.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`A Text Input is used to collect user data that includes words, numbers, or characters.`}</li>
          <li parentName="ul">{`Use helper text to instruct the user what the proper input type may be.`}</li>
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/placeholder-text"
            }}>{`placeholder text`}</a>{` only to provide examples of proper
inputs. Never use placeholder text to help instruct users.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If the user needs to input a time, use a time picker.`}</li>
          <li parentName="ul">{`If the user needs to input a date, use a date picker.`}</li>
          <li parentName="ul">{`If input styling is needed, such as for configuring email messages, use a Rich Text Editor.`}</li>
          <li parentName="ul">{`Use a Text Area when you need to let users enter an amount of text that’s longer than a single
line.`}</li>
          <li parentName="ul">{`Consider using a Select, Radio or Checkboxes if there is predetermined data that a user should not
input themself.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Do use helper text to display important information that aids in the successful completion of an input." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/23aec1dabd19ef7d9044f32bbe75ae26/82158/text-input-mobile-do-helper.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "53.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating proper use of helper text below the input.",
                    "title": "Image demonstrating proper use of helper text below the input.",
                    "src": "/static/23aec1dabd19ef7d9044f32bbe75ae26/82158/text-input-mobile-do-helper.png",
                    "srcSet": ["/static/23aec1dabd19ef7d9044f32bbe75ae26/1efb2/text-input-mobile-do-helper.png 370w", "/static/23aec1dabd19ef7d9044f32bbe75ae26/82158/text-input-mobile-do-helper.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't use instructional text as placeholder text because it will disappear as the user starts typing and will no longer be helpful to the user." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/95834cf23598be8fe96622b0a906017c/82158/text-input-mobile-dont-placeholder.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "53.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating the misuse of placeholder text within an input.",
                    "title": "Image demonstrating the misuse of placeholder text within an input.",
                    "src": "/static/95834cf23598be8fe96622b0a906017c/82158/text-input-mobile-dont-placeholder.png",
                    "srcSet": ["/static/95834cf23598be8fe96622b0a906017c/1efb2/text-input-mobile-dont-placeholder.png 370w", "/static/95834cf23598be8fe96622b0a906017c/82158/text-input-mobile-dont-placeholder.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "mobile-guidance"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Mobile text inputs are larger in height to better accommodate finger interaction.`}</li>
          <li parentName="ul">{`Mobile text inputs have larger typography for better readability on smaller screens.`}</li>
        </ul>
        <h3 {...{
          "id": "truncation"
        }}>{`Truncation`}</h3>
        <p><strong parentName="p">{`Labels`}</strong></p>
        <p>{`Labels should be short and concise. They do not have a limit on how long they may be. Once the label
exceeds the length of the container it will wrap to another line.`}</p>
        <p><strong parentName="p">{`Helper Text`}</strong></p>
        <p>{`Helper Text should be short and concise. They do not have a limit on how long they may be. Once
helper text exceeds the length of the container it will wrap to another line.`}</p>
        <p><strong parentName="p">{`Input Text`}</strong></p>
        <p>{`When the input text exceeds the length of the container the text becomes truncated at the trailing
end of the container while inactive. While actively typing into the input, the text scrolls off the
leading edge of the container.`}</p>
        <h3 {...{
          "id": "keyboards"
        }}>{`Keyboards`}</h3>
        <p>{`Mobile devices utilize on-screen keyboards. It is often up to the designer to specify keyboard-type
in their designs for the intended input. These keyboards vary based on the brand of the device;
however, most devices have dedicated keyboards for specific types of inputs such as:`}</p>
        <ul>
          <li parentName="ul">{`Alphabetic`}</li>
          <li parentName="ul">{`Numeric`}</li>
          <li parentName="ul">{`Alphanumeric`}</li>
          <li parentName="ul">{`Email`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use the appropriate keyboard based on the intended input." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/a17d7e11cd479f3b91552d98c0426b3d/82158/text-input-mobile-ios-do-keyboard.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating pairing the proper keyboard for the text input.",
                    "title": "Image demonstrating pairing the proper keyboard for the text input.",
                    "src": "/static/a17d7e11cd479f3b91552d98c0426b3d/82158/text-input-mobile-ios-do-keyboard.png",
                    "srcSet": ["/static/a17d7e11cd479f3b91552d98c0426b3d/1efb2/text-input-mobile-ios-do-keyboard.png 370w", "/static/a17d7e11cd479f3b91552d98c0426b3d/82158/text-input-mobile-ios-do-keyboard.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't use the wrong keyboard for an input as this causes more work for the user. This could prevent the user from completing their task." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/aa605a5bfe29d4377c9d71779e494d9e/82158/text-input-mobile-ios-dont-keyboard.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating pairing the wrong keyboard for the text input.",
                    "title": "Image demonstrating pairing the wrong keyboard for the text input.",
                    "src": "/static/aa605a5bfe29d4377c9d71779e494d9e/82158/text-input-mobile-ios-dont-keyboard.png",
                    "srcSet": ["/static/aa605a5bfe29d4377c9d71779e494d9e/1efb2/text-input-mobile-ios-dont-keyboard.png 370w", "/static/aa605a5bfe29d4377c9d71779e494d9e/82158/text-input-mobile-ios-dont-keyboard.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "screen-size-differences"
        }}>{`Screen Size Differences`}</h3>
        <p>{`Typically, inputs on mobile should stretch to the full width of the screen. However, there are some
exceptions for providing limited space to set expectations of the input, such as providing a smaller
text input box for entering an address ZIP code. For larger mobile devices, inputs should have a
maximum width of 600 pts to retain proper readability.`}</p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <p><inlineCode parentName="p">{`LabelledTextField`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct LabelledTextField: View
`}</code></pre>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{` public init(
    featureData: FeatureMetricsData,
    text: Binding<String>,
    placeholderText: String? = nil,
    label: String,
    accessibilityLabel: String,
    textFieldButtonAccessibilityLabel: String,
    helperText: String? = nil,
    context: Binding<SemanticContext>,
    isSecure: Bool = false,
    keyboardType: UIKeyboardType = .default,
    onSubmission: (() -> Void)? = nil
 )
`}</code></pre>
        <p>{`Create an instance of LabelledTextField.`}</p>
        <h4 {...{
          "id": "parameters"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`featureData`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`FeatureMetricsData`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`text`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Binding<String>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Text binding so the clear button can work, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`placeholderText`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Label for possible content of the field, already localized. Default is `}<inlineCode parentName="td">{`nil`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`label`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Label to be positioned above field, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`accessibilityLabel`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Accessibility label of field, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`textFieldButtonAccessibilityLabel`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Accessibility label of the text field button (re: "x" clear button).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`helperText`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Helper text below field, already localized. Default is `}<inlineCode parentName="td">{`nil`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`localizer`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`LocalizationAdapting`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Localization provider`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`context`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the field.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`isSecure`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Bool`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional `}<inlineCode parentName="td">{`bool`}</inlineCode>{` which allows you to use a SecureField instead of a TextField. Default is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`keyboardType`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`UIKeyboardType`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`default`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional `}<inlineCode parentName="td">{`UIKeyboardType`}</inlineCode>{` which allows you to change the keyboard type used with the TextField. Default is `}<inlineCode parentName="td">{`.default`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`onSubmission`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(() -> Void)?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional closure which allows you to listen to the `}<inlineCode parentName="td">{`onSubmit`}</inlineCode>{` action from the `}<inlineCode parentName="td">{`TextField`}</inlineCode>{` or `}<inlineCode parentName="td">{`SecureField`}</inlineCode>{`. Default is `}<inlineCode parentName="td">{`nil`}</inlineCode>{`.`}</td>
            </tr>
          </tbody>
        </table>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    featureData: FeatureMetricsData,
    text: Binding<String>,
    placeholderText: String? = nil,
    label: String,
    helperText: String?,
    context: Binding<SemanticContext>,
    localizer: LocalizationAdapting
)
`}</code></pre>
        <p>{`Create an instance of LabelledTextField.`}</p>
        <h4 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`featureData`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`FeatureMetricsData`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`text`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Binding<String>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Text binding so the clear button can work, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`placeholderText`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Label for possible content of the field, already localized. Default is `}<inlineCode parentName="td">{`nil`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`label`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Label to be positioned above field, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`helperText`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Helper text below field, already localized. Default is `}<inlineCode parentName="td">{`nil`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`context`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the field.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`localizer`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`LocalizationAdapting`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Localization provider`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/91b42122e2e91fc2f633a3040e16039f/536c7/text-input-mobile-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.135135135135133%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input with annotation markers describing the anatomy.",
                "title": "Image of a Text Input with annotation markers describing the anatomy.",
                "src": "/static/91b42122e2e91fc2f633a3040e16039f/50383/text-input-mobile-anatomy.png",
                "srcSet": ["/static/91b42122e2e91fc2f633a3040e16039f/1efb2/text-input-mobile-anatomy.png 370w", "/static/91b42122e2e91fc2f633a3040e16039f/50383/text-input-mobile-anatomy.png 740w", "/static/91b42122e2e91fc2f633a3040e16039f/536c7/text-input-mobile-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Text used as a title or brief description of the input.`}</li>
          <li parentName="ol"><strong parentName="li">{`Placeholder/Input Text:`}</strong>{` Placeholder text is optional and shows an example of the intended
input.`}</li>
          <li parentName="ol"><strong parentName="li">{`Helper Text (Optional):`}</strong>{` Message to aid in users understanding of acceptable input text.`}</li>
          <li parentName="ol"><strong parentName="li">{`Input Container:`}</strong>{` Rectangular container that houses the placeholder and input text.`}</li>
          <li parentName="ol"><strong parentName="li">{`Required Asterisk (Optional):`}</strong>{` Appears only when the input is required.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states-1"
        }}>{`Interaction States`}</h2>
        <p>{`Mobile inputs include several interactive states; inactive, active, disabled and pressed states.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/536c7/text-input-mobile-inactive.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its inactive state.",
                "title": "Image of a Text Input in its inactive state.",
                "src": "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/50383/text-input-mobile-inactive.png",
                "srcSet": ["/static/8aef08d098c8ef4e53cf5e6a5bb40b08/1efb2/text-input-mobile-inactive.png 370w", "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/50383/text-input-mobile-inactive.png 740w", "/static/8aef08d098c8ef4e53cf5e6a5bb40b08/536c7/text-input-mobile-inactive.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Inactive states are the default state if the input is not active, disabled or displaying an alert.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dabf9ad1730051eb432c41e643edfb4e/536c7/text-input-mobile-active.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its active state.",
                "title": "Image of a Text Input in its active state.",
                "src": "/static/dabf9ad1730051eb432c41e643edfb4e/50383/text-input-mobile-active.png",
                "srcSet": ["/static/dabf9ad1730051eb432c41e643edfb4e/1efb2/text-input-mobile-active.png 370w", "/static/dabf9ad1730051eb432c41e643edfb4e/50383/text-input-mobile-active.png 740w", "/static/dabf9ad1730051eb432c41e643edfb4e/536c7/text-input-mobile-active.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Active states are displayed with a blue border, a cursor, and a keyboard.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f4715750c73552fa995b4213c428c497/536c7/text-input-mobile-disabled.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its disabled state.",
                "title": "Image of a Text Input in its disabled state.",
                "src": "/static/f4715750c73552fa995b4213c428c497/50383/text-input-mobile-disabled.png",
                "srcSet": ["/static/f4715750c73552fa995b4213c428c497/1efb2/text-input-mobile-disabled.png 370w", "/static/f4715750c73552fa995b4213c428c497/50383/text-input-mobile-disabled.png 740w", "/static/f4715750c73552fa995b4213c428c497/536c7/text-input-mobile-disabled.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Disabled states are grayed out to prevent users from interacting with the input.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/87723c0344932fed38cced149c39887a/536c7/text-input-mobile-pressed.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.567567567567565%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Text Input in its pressed state.",
                "title": "Image of a Text Input in its pressed state.",
                "src": "/static/87723c0344932fed38cced149c39887a/50383/text-input-mobile-pressed.png",
                "srcSet": ["/static/87723c0344932fed38cced149c39887a/1efb2/text-input-mobile-pressed.png 370w", "/static/87723c0344932fed38cced149c39887a/50383/text-input-mobile-pressed.png 740w", "/static/87723c0344932fed38cced149c39887a/536c7/text-input-mobile-pressed.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`While actively pressing on an input, the container fill is displayed in gray to provide feedback to
the user of their touch.`}</p>
        <h2 {...{
          "id": "notification-states-1"
        }}>{`Notification States`}</h2>
        <p>{`Notification states on mobile include an additional visual affordance compared to web notifications.
They include an icon and a notification label.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/85bd863f552721e27efbf02f4218aceb/536c7/text-input-mobile-notification.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "20.54054054054054%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of two Text Input showing the notification states.",
                "title": "Image of two Text Input showing the notification states.",
                "src": "/static/85bd863f552721e27efbf02f4218aceb/50383/text-input-mobile-notification.png",
                "srcSet": ["/static/85bd863f552721e27efbf02f4218aceb/1efb2/text-input-mobile-notification.png 370w", "/static/85bd863f552721e27efbf02f4218aceb/50383/text-input-mobile-notification.png 740w", "/static/85bd863f552721e27efbf02f4218aceb/536c7/text-input-mobile-notification.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`A Text Input is used to collect user data that includes words, numbers, or characters.`}</li>
          <li parentName="ul">{`Use helper text to instruct the user what the proper input type may be.`}</li>
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/placeholder-text"
            }}>{`placeholder text`}</a>{` only to provide examples of proper
inputs. Never use placeholder text to help instruct users.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If the user needs to input a time, use a time picker.`}</li>
          <li parentName="ul">{`If the user needs to input a date, use a date picker.`}</li>
          <li parentName="ul">{`If input styling is needed, such as for configuring email messages, use a Rich Text Editor.`}</li>
          <li parentName="ul">{`Use a Text Area when you need to let users enter an amount of text that’s longer than a single
line.`}</li>
          <li parentName="ul">{`Consider using a Select, Radio or Checkboxes if there is predetermined data that a user should not
input themself.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Do use helper text to display important information that aids in the successful completion of an input." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/23aec1dabd19ef7d9044f32bbe75ae26/82158/text-input-mobile-do-helper.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "53.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating proper use of helper text below the input.",
                    "title": "Image demonstrating proper use of helper text below the input.",
                    "src": "/static/23aec1dabd19ef7d9044f32bbe75ae26/82158/text-input-mobile-do-helper.png",
                    "srcSet": ["/static/23aec1dabd19ef7d9044f32bbe75ae26/1efb2/text-input-mobile-do-helper.png 370w", "/static/23aec1dabd19ef7d9044f32bbe75ae26/82158/text-input-mobile-do-helper.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't use instructional text as placeholder text because it will disappear as the user starts typing and will no longer be helpful to the user." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/95834cf23598be8fe96622b0a906017c/82158/text-input-mobile-dont-placeholder.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "53.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating the misuse of placeholder text within an input.",
                    "title": "Image demonstrating the misuse of placeholder text within an input.",
                    "src": "/static/95834cf23598be8fe96622b0a906017c/82158/text-input-mobile-dont-placeholder.png",
                    "srcSet": ["/static/95834cf23598be8fe96622b0a906017c/1efb2/text-input-mobile-dont-placeholder.png 370w", "/static/95834cf23598be8fe96622b0a906017c/82158/text-input-mobile-dont-placeholder.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "mobile-guidance-1"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Mobile text inputs are larger in height to better accommodate finger interaction.`}</li>
          <li parentName="ul">{`Mobile text inputs have larger typography for better readability on smaller screens.`}</li>
        </ul>
        <h3 {...{
          "id": "truncation-1"
        }}>{`Truncation`}</h3>
        <p><strong parentName="p">{`Labels`}</strong></p>
        <p>{`Labels should be short and concise. They do not have a limit on how long they may be. Once the label
exceeds the length of the container it will wrap to another line.`}</p>
        <p><strong parentName="p">{`Helper Text`}</strong></p>
        <p>{`Helper Text should be short and concise. They do not have a limit on how long they may be. Once
helper text exceeds the length of the container it will wrap to another line.`}</p>
        <p><strong parentName="p">{`Input Text`}</strong></p>
        <p>{`When the input text exceeds the length of the container the text becomes truncated at the trailing
end of the container while inactive. While actively typing into the input, the text scrolls off the
leading edge of the container.`}</p>
        <h3 {...{
          "id": "keyboards-1"
        }}>{`Keyboards`}</h3>
        <p>{`Mobile devices utilize on-screen keyboards. It is often up to the designer to specify keyboard-type
in their designs for the intended input. These keyboards vary based on the brand of the device;
however, most devices have dedicated keyboards for specific types of inputs such as:`}</p>
        <ul>
          <li parentName="ul">{`Alphabetic`}</li>
          <li parentName="ul">{`Numeric`}</li>
          <li parentName="ul">{`Alphanumeric`}</li>
          <li parentName="ul">{`Email`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use the appropriate keyboard based on the intended input." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/dbf7db10764a7ab653e7c0a977835302/82158/text-input-mobile-android-do-keyboard.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "91.08108108108108%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating pairing the proper keyboard for the text input.",
                    "title": "Image demonstrating pairing the proper keyboard for the text input.",
                    "src": "/static/dbf7db10764a7ab653e7c0a977835302/82158/text-input-mobile-android-do-keyboard.png",
                    "srcSet": ["/static/dbf7db10764a7ab653e7c0a977835302/1efb2/text-input-mobile-android-do-keyboard.png 370w", "/static/dbf7db10764a7ab653e7c0a977835302/82158/text-input-mobile-android-do-keyboard.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't use the wrong keyboard for an input as this causes more work for the user. This could prevent the user from completing their task." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/446e28a1cb90432c537a926ca0aa1b5a/82158/text-input-mobile-android-dont-keyboard.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "91.08108108108108%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating pairing the wrong keyboard for the text input.",
                    "title": "Image demonstrating pairing the wrong keyboard for the text input.",
                    "src": "/static/446e28a1cb90432c537a926ca0aa1b5a/82158/text-input-mobile-android-dont-keyboard.png",
                    "srcSet": ["/static/446e28a1cb90432c537a926ca0aa1b5a/1efb2/text-input-mobile-android-dont-keyboard.png 370w", "/static/446e28a1cb90432c537a926ca0aa1b5a/82158/text-input-mobile-android-dont-keyboard.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "screen-size-differences-1"
        }}>{`Screen Size Differences`}</h3>
        <p>{`Typically, inputs on mobile should stretch to the full width of the screen. However, there are some
exceptions for providing limited space to set expectations of the input, such as providing a smaller
text input box for entering an address ZIP code. For larger mobile devices, inputs should have a
maximum width of 600 pts to retain proper readability.`}</p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun TextInputUiComponent(
    modifier: Modifier = Modifier,
    label: String? = null,
    value: String,
    onValueChange: (String) -> Unit,
    readOnly: Boolean = false,
    placeholder: String? = null,
    placeholderTextStyle: TextStyle = WorkdayTheme.canvasTypography.bodyMedium,
    heightConfig: TextInputHeightConfig = singleLineConfig(),
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
    helperText: String? = null,
    onClickInputText: (() -> Unit) = {},
    onClickClearTextIcon: (() -> Unit) = {},
    keyboardOptions: KeyboardOptions = KeyboardOptions.Default,
    keyboardActions: KeyboardActions = KeyboardActions(),
    semanticState: SemanticState = SemanticState()
) {
`}</code></pre>
        <h3 {...{
          "id": "parameters-2"
        }}>{`Parameters`}</h3>
        <p>{`All undocumented parameters are native Android Compose types that can be found in the developer
documentation.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`label`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The text above the input that typically designates the name of the component`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`value`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String `}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The text to be displayed inside of the text input`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`onValueChange`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(String) -> Unit)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Callback lambda that is executed whenever the text of the input changes.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`readOnly`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Removes the ability for user input into the text input. The entire Input surface provides a ripple click animation when readOnly is set to true.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`placeholder`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`This text is shown inside of the input when the value is empty to provide a hint to the user.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`placeholderTextStyle`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`TextStyle`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`bodySmall`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Configures the placeholder typography style.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`heightConfig`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`TextInputHeightConfig`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`singleLineConfig()`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Toggles between a `}<inlineCode parentName="td">{`TextInput`}</inlineCode>{` and `}<inlineCode parentName="td">{`TextArea`}</inlineCode>{` UI Component. Provides min height and fixed height configurations for multi-line inputs.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`leadingIcon`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Composable?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Icon composable rendered at the start of the input area.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`trailingIcon`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Composable?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Icon composable rendered at the end of the input area.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`helperText`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Helper text is displayed underneath the input area that to give additional context to the user. Error and Alert helper text prefixes are localized and automatically displayed when the component’s semantic state is changed`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`onClickInputText`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(() -> Unit)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`{}`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Callback lambda that is executed when the user clicks on the Input area.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`onClickClearTextIcon`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(() -> Unit)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`{}`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Callback lambda that is executed when the user clicks on the “clear text” trailing Icon that is displayed while the component is focused.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`semanticState`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticState`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SemanticState()`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Adjusts the state of the Component. This allows for enabling, disabling, warning, error, and required states.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      